<template>
  <v-form ref="form" v-model="pValid" lazy-validation @submit="onSubmit">
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="new-service-col">
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="nameRules"
          label="Slide Name"
          :helpText="'Provide a name for the slide'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.description"
          label="Description"
          :helpText="'You can provide a description to add more information about the slide'"
          :textarea="true"
          required
        ></HelpFormInputVue>

        <FormSection
          :label="'Tags'"
          class="mt-0"
          :icon="'mdi-tag-multiple-outline'"
          :actions="actions"
          @onCreate="() => $emit('onTagCreate')"
          underline
          right
        ></FormSection>

        <SelectorKnowledgeBaseTagsVue
          dark
          autoload
          label="Tags"
          helpText="You can provide a set of tags to make note connected with others under the same tag"
          return-object
          v-model="form.tags"
          multiple
        >
        </SelectorKnowledgeBaseTagsVue>

        <VueFileAgent
          ref="vueFileAgent"
          deletable
          :linkable="true"
          :multiple="false"
          accept=".ppt, .pptx"
          :editable="false"
          v-model="form.file"
        ></VueFileAgent>
      </v-col>
    </v-row>
  </v-form>
</template>
      
      
  <script>
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import SelectorKnowledgeBaseTagsVue from "../../../../../../components/wad/atoms/common/autocompletes/SelectorKnowledgeBaseTags.vue";
import FormSection from "../../../../../../components/wad/atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../../../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import { TAGS_BUTTONS } from "../../../knowledge-base/constants/actions.gh";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Name is required"],

      fileRecords: [],
      fileRecordsForUpload: [], // maintain an upload queue
    };
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        TAGS_BUTTONS.CREATE_NEW,
        this.permissions
      );
    },
  },
  components: {
    HelpFormInputVue,
    SelectorKnowledgeBaseTagsVue,
    FormSection,
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.$emit("onSubmit");
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    filesSelected: function (fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      if (validFileRecords[0]) this.form.file = validFileRecords[0];
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
      
      
  <style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>